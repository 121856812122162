import React, { useState, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { graphql, withPrefix } from 'gatsby'
import styled from 'styled-components'

import Heading from '../../shared/section-header'
import {
  Grid,
  Card,
  CardActionCall,
  CardTextGroup,
  MoreCard,
} from '../../shared/card-grid'
import { absoluteSize } from '../../shared/common-styles'
import { p as P } from '../../shared/markdown-defaults'
import Page, { Section } from '../../shared/page-commons'
import { ACTBanner } from '../../shared/banner'

const Ratio = styled.div`
  padding-bottom: 100%;
`

const Image = styled.img`
  ${absoluteSize};
  display: block;
  object-fit: cover;
`

const Name = styled.h3`
  font-size: 1.125rem;
  color: var(--heading);
`

const ResearchArea = styled.p`
  flex-grow: 1;
  margin-bottom: 30px;
  margin-top: 5px;
  color: var(--text);
`

const MemberCard = React.memo((props) => {
  const { member, ...restProps } = props
  const image_source =
    member.image_src || '/images/about/south_park/WHO_ANONYMOUS.jpg'

  return (
    <Card {...restProps}>
      <figure css="position: relative;">
        <Ratio />
        <Image src={withPrefix(image_source)} alt={member.title} />
      </figure>
      <CardTextGroup>
        <Name>{member.title}</Name>
        <ResearchArea>{member.headline}</ResearchArea>
        <CardActionCall>About</CardActionCall>
      </CardTextGroup>
    </Card>
  )
})

// the data prop has the graphql data
export default function MembersPage(props) {
  const [showingAll, setShowingAll] = useState(false)

  const currentMembers = props.data.currentMembers.nodes

  const formerMembers = useMemo(() => {
    if (showingAll) return props.data.formerMembers.nodes
    return props.data.formerMembers.nodes.slice(0, 9)
  }, [props.data.formerMembers.nodes, showingAll])

  return (
    <Page>
      <Helmet>
        <meta
          name="description"
          content="The ACT consists of Young Graduate Trainees, Stagiaires and Research Fellows (PhDs)."
        />
        <meta
          property="og:description"
          content="The ACT consists of Young Graduate Trainees, Stagiaires and Research Fellows (PhDs)."
        />
        <meta
          name="twitter:description"
          content="The ACT consists of Young Graduate Trainees, Stagiaires and Research Fellows (PhDs)."
        />
      </Helmet>
      <ACTBanner src="/images/core/team_banner.jpg" children="info" />
      <Section>
        <Heading>Current members of the ACT</Heading>
        <P>The researchers below are currently members of the team.</P>
        <Grid css="margin-top: 50px;">
          {currentMembers.map(({ frontmatter, fields }) => (
            <MemberCard
              key={frontmatter.title}
              member={frontmatter}
              document={frontmatter.document_url}
              href={frontmatter.external_url}
              to={frontmatter.internal_url || fields.slug}
            />
          ))}
        </Grid>
      </Section>
      <Section>
        <Heading>Former members of the ACT</Heading>
        <P>
          The ACT Alumni community maintains a large network of experts to
          foster science and space research.
        </P>
        <Grid css="margin-top: 50px;">
          {formerMembers.map(({ frontmatter, fields }) => (
            <MemberCard
              key={frontmatter.title}
              member={frontmatter}
              document={frontmatter.document_url}
              href={frontmatter.external_url}
              to={frontmatter.internal_url || fields.slug}
            />
          ))}
          {!showingAll && (
            <MoreCard onClick={() => setShowingAll(true)}>
              Show all former members
            </MoreCard>
          )}
        </Grid>
      </Section>
    </Page>
  )
}

// query the required data (all current members) from the graphql nodes
export const query = graphql`
  query Members {
    currentMembers: allMdx(
      filter: {
        frontmatter: { ongoing: { eq: true } }
        fileAbsolutePath: { glob: "**/src/collections/team/**" }
      }
      sort: { fields: fields___randomNumber, order: DESC }
    ) {
      nodes {
        frontmatter {
          title
          date
          headline
          image_src
          document_url
          external_url
          internal_url
        }
        fields {
          slug
        }
      }
    }
    formerMembers: allMdx(
      filter: {
        frontmatter: { ongoing: { eq: false } }
        fileAbsolutePath: { glob: "**/src/collections/team/**" }
      }
      sort: { fields: frontmatter___date_left, order: DESC }
    ) {
      nodes {
        frontmatter {
          title
          date
          headline
          image_src
          document_url
          external_url
          internal_url
        }
        fields {
          slug
        }
      }
    }
  }
`
